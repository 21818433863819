import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import React, { useState } from "react";
import "./EventAssignButton.less";
import { postUnassignGuest } from "../../../../../../../apis/eventsApi";
import { SOMETHING_WENT_WRONG } from "../../../../../../../utils/constants";
const EventAssignButton = ({
  assignUnassignButton,
  setAssignUnassignButton,
  unAssignTravellers,
  assignTravellers,
  SetAssignTravellers,
  selectedInventory,
  selectedTraveler,
  setRefreshData,
  setUnassignTravellers,
  setSelectedTraveler,
  setSelectedInventory,
  setSelectedRowKeys,
  setSelectedCheckBoxKeys,
  setReservationHoldInventory,
  reservationHoldInventory,
  setButtonClicked,
  setLeftTableLoading,
  setRightTableLoading
}) => {
  const [size, setSize] = useState("large");
  const travelersList =
    unAssignTravellers &&
    unAssignTravellers.map((traveler) => {
      return { id: traveler?.id, reservation_uuid: traveler?.reservation_uuid };
    });
    const assignUnassignGuest = async (payload) => {
    setRefreshData(true);
    setButtonClicked(true);
    setRightTableLoading(true)
    setLeftTableLoading(true)
    setAssignUnassignButton({
      left: true,
      right: true,
    })
    const response = await postUnassignGuest(payload);
    if (response && response.status) {
      setUnassignTravellers([]);
      setSelectedTraveler([]);
      setSelectedInventory({});
      setSelectedCheckBoxKeys([]);
      setSelectedRowKeys([]);
      setReservationHoldInventory({})
    } else {
      message.error(response?.data?.response?.data?.message || SOMETHING_WENT_WRONG);
    }
    setButtonClicked(false)
  };
  return (
    <>
      <div className="Event_assignbtn_main">
        <Button
          disabled={assignUnassignButton?.left}
          icon={<LeftOutlined />}
          onClick={() => {
            let payload = {}
            if(assignTravellers.length){
               payload = {
                data: {
                  operation_key: "unassign",
                  items: assignTravellers,
                },
              };
            }
            
            assignUnassignGuest(payload);
          }}
          size={size}
        />

        <Button
          disabled={assignUnassignButton?.right || !selectedInventory?.inventory_uuid || !travelersList.length}
          icon={<RightOutlined />}
          onClick={() => {
            let payload = {
              data: {
                inventory_uuid: selectedInventory?.inventory_uuid,
                operation_key: "assign",
                hold_type: selectedInventory?.hold_type,
                travelers: travelersList,
              },
            };
            assignUnassignGuest(payload);
          }}
          size={size}
        />
      </div>
    </>
  );
};
export default EventAssignButton;
