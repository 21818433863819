import { Layout, Col, Row, Grid } from "antd";

import EventUnassignGuestTable from "../../components/Events/EventDetailsTabs/AddonsTab/AddonDetails/AssignDining/EventUnassignGuestTable";
import EventAssignButton from "../../components/Events/EventDetailsTabs/AddonsTab/AddonDetails/AssignDining/EventAssignButton/EventAssignButton";
import EventAssignDiningTable from "../../components/Events/EventDetailsTabs/AddonsTab/AddonDetails/AssignDining/EventAssignDiningTable.jsx";
import "./EventAssignDining.less";

import React, { useState } from "react";
import BreadCrumbGenerator from "../../components/BreadCrumbGenerator/BreadCrumbGenerator";
import EventAssignDiningHeader from "../../components/Events/EventDetailsTabs/AddonsTab/AddonDetails/AssignDining/EventAssignDiningHeader";
import { useSearchParams } from "react-router-dom";
import {
  EventDetailsAddonsDetailsRoute,
  EventDetailsRoute,
  EventsListingRoute,
} from "../../url-routes/urlRoutes";
const { Content } = Layout;

function EventAssignDining() {
  const { useBreakpoint } = Grid;
  const { lg } = useBreakpoint();
  const mobileClass = lg || lg === undefined ? "" : "mobileview";
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTraveler, setSelectedTraveler] = useState();
  const [selectedInventory, setSelectedInventory] = useState();
  const [reservationHoldInventory, setReservationHoldInventory] = useState();
  const [refreshData, setRefreshData] = useState();
  const [buttonClicked, setButtonClicked] = useState(false);
  const [leftTableLoading, setLeftTableLoading] = useState(false);
  const [rightTableLoading, setRightTableLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedCheckBoxKeys, setSelectedCheckBoxKeys] = useState([]);
  const [assignUnassignButton, setAssignUnassignButton] = useState({
    left: true,
    right: true,
  });
  const [isReservationHold, setIsReservationHold] = useState(false);
  const [unAssignTravellers, setUnassignTravellers] = useState([]);
  const [ assignTravellers, SetAssignTravellers] = useState([]);
  const eventName = searchParams.get("event");
  const addOnUuid = searchParams.get("addonId");
  const eventId = searchParams.get("id");
  const addOnType = searchParams.get("addonType");
  const label = searchParams.get("label");

  const breadCrumbs = [
    { title: "Events", path: EventsListingRoute },
    { title: "Events Details", path: `${EventDetailsRoute}?id=${eventId}` },
    { title: "Add -Ons", path: `${EventDetailsRoute}?id=${eventId}#Add-Ons` },
    {
      title: "Add -Ons Detail",
      path: `${EventDetailsAddonsDetailsRoute}?id=${EventDetailsRoute}&addonId=${addOnUuid}&addonType=${addOnType}`,
    },
    { title: label, path: "" },
  ];

  return (
    <Layout className={mobileClass}>
      <Layout>
        <Layout>
          <BreadCrumbGenerator breadCrumbs={breadCrumbs} />
          <EventAssignDiningHeader />
          <div>
            <div className="event_dining_layout">
              <Row>
                <Col
                  sm={24}
                  md={24}
                  lg={11}
                  xl={11}
                  xxl={11}
                  className="leftunussigned-section"
                >
                  <Content>
                    <div>
                      <EventUnassignGuestTable
                        setLoading={setLeftTableLoading}
                        loading={leftTableLoading}
                        setReservationHoldInventory={
                          setReservationHoldInventory
                        }
                        isReservationHold={isReservationHold}
                        selectedRowKeys={selectedCheckBoxKeys}
                        setSelectedRowKeys={setSelectedCheckBoxKeys}
                        setRadioRowKeys={setSelectedRowKeys}
                        setAssignUnassignButton={setAssignUnassignButton}
                        setUnassignTravellers={setUnassignTravellers}
                        setSelectedTraveler={setSelectedTraveler}
                        selectedTraveler={selectedTraveler}
                        refreshData={refreshData}
                        setRefreshData={setRefreshData}
                        buttonClicked={buttonClicked}
                      />
                    </div>
                  </Content>
                </Col>
                <Col
                  sm={24}
                  md={24}
                  lg={1}
                  xl={1}
                  xxl={1}
                  className="centerassigned-btn"
                >
                  <EventAssignButton
                    setLeftTableLoading={setLeftTableLoading}
                    setRightTableLoading={setRightTableLoading}
                    setButtonClicked={setButtonClicked}
                    setUnassignTravellers={setUnassignTravellers}
                    SetAssignTravellers={SetAssignTravellers}
                    setSelectedTraveler={setSelectedTraveler}
                    setSelectedInventory={setSelectedInventory}
                    reservationHoldInventory={reservationHoldInventory}
                    setAssignUnassignButton={setAssignUnassignButton}
                    setReservationHoldInventory={setReservationHoldInventory}
                    assignUnassignButton={assignUnassignButton}
                    unAssignTravellers={unAssignTravellers}
                    assignTravellers={assignTravellers}
                    selectedInventory={selectedInventory}
                    selectedTraveler={selectedTraveler}
                    setRefreshData={setRefreshData}
                    setSelectedRowKeys={setSelectedRowKeys}
                    setSelectedCheckBoxKeys={setSelectedCheckBoxKeys}
                  />
                </Col>

                <Col
                  sm={24}
                  md={24}
                  lg={12}
                  xl={12}
                  xxl={12}
                  className="rightunussigned-section"
                >
                  <Content>
                    <div>
                      <EventAssignDiningTable
                        setLoading={setRightTableLoading}
                        loading={rightTableLoading}
                        setUnassignTravellers={setUnassignTravellers}
                        SetAssignTravellers={SetAssignTravellers}
                        setSelectedCheckBoxKeys={setSelectedCheckBoxKeys}
                        selectedRowKeys={selectedRowKeys}
                        setSelectedRowKeys={setSelectedRowKeys}
                        setSelectedTraveler={setSelectedTraveler}
                        setAssignUnassignButton={setAssignUnassignButton}
                        setSelectedInventory={setSelectedInventory}
                        setReservationHoldInventory={
                          setReservationHoldInventory
                        }
                        refreshData={refreshData}
                        setRefreshData={setRefreshData}
                        setIsReservationHold={setIsReservationHold}
                        buttonClicked={buttonClicked}
                      />
                    </div>
                  </Content>
                </Col>
              </Row>
            </div>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
}
export default EventAssignDining;
