import { UpOutlined } from "@ant-design/icons";
import { Table, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { addItemApi, addReservationItem } from "../../../apis/reservationApi";
import { useNavigate } from "react-router-dom";
import { ReservationDetailRoute } from "../../../url-routes/urlRoutes";
import { SOMETHING_WENT_WRONG } from "../../../utils/constants";
import { setSectionHighlighter } from "../../../redux/features/reservationFeatures/reservationSlice";
import { useDispatch } from "react-redux";
// import Expandinterior_table from './Expandinterior_table';

function Reservationprimary_table(props) {
  const {
    eventData,
    page,
    setUuid,
    setSearchParams,
    paramId,
    searchParams,
    loading,
    travelers,
    selectedTravelers,
    setIsModalOpen,
    modalRef,
    isModalOpen,
  } = props;
  const [column, setColumn] = useState();
  const [addLoader, setAddLoader] = useState(false);
  let reservationId = searchParams.get("id");
  let eventId = searchParams.get("event_id");
  let categoryId = searchParams.get("category_id");
  let replaceItem = searchParams.get("replace-item");
  let parentResUuid = searchParams.get("res-item-uuid");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (page === "category") {
      setColumn([
        {
          title: "Code Name",
          dataIndex: "code",
          key: "code",
        },

        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Description",
          dataIndex: "description",
          key: "description",
        },
        {
          title: "Fees",
          dataIndex: "fees",
          key: "fees",
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "Discount",
          dataIndex: "discount",
          key: "discount",
        },
        {
          title: "Selling Price",
          dataIndex: "selling_price",
          key: "selling_price",
        },
        Table.EXPAND_COLUMN,
      ]);
    } else if (page === "inventory") {
      setColumn([
        {
          title: "Code",
          dataIndex: "code",
          key: "code",
        },

        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
        },
        {
          title: "Price",
          dataIndex: "price",
          key: "price",
        },
        {
          title: "Fees",
          dataIndex: "fees",
          key: "fees",
        },
        {
          title: "Discount",
          dataIndex: "discount",
          key: "discount",
        },
        {
          title: "Selling Price",
          dataIndex: "selling_price",
          key: "selling_price",
        },
        Table.EXPAND_COLUMN,
      ]);
    } else {
      setColumn([
        {
          title: "ID",
          dataIndex: "hash",
          key: "hash",
        },

        {
          title: "Type",
          dataIndex: "type",
          key: "type",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Start Date",
          dataIndex: "start_date",
          key: "start_date",
        },

        {
          title: "End Date",
          dataIndex: "end_date",
          key: "end_date",
        },
        Table.EXPAND_COLUMN,
      ]);
    }
  }, [page]);

  const setURL = (uuid) => {
    let url = {};
    if (page === "event") {
      url.step = "category";
      if (reservationId) {
        url.id = reservationId;
      }
      url.event_id = uuid;
    } else if (page === "category") {
      url.step = "inventory";
      if (replaceItem) {
        url[`replace-item`] = true;
      }
      if (parentResUuid) {
        url[`res-item-uuid`] = parentResUuid;
      }
      if (reservationId) {
        url.id = reservationId;
      }
      if (eventId) {
        url.event_id = eventId;
      }
      url.category_id = uuid;
    }
    return url;
  };

  const itemSelector = (record) => {
    const payload = {
      data: {
        reservation_id: reservationId,
        category_id: categoryId,
        inventory_id: record?.uuid,
        inventory_hold_status: record?.field_machinename,
        parent_res_item: parentResUuid,
      },
    };
    let travelersId = [];
    travelers.forEach((element) => {
      if (selectedTravelers.includes(element.id)) {
        travelersId.push({ id: element?.id });
      }
    });
    payload.data.travelers = travelersId;
    addReservationItem(payload);
  };

  const addReservationItem = async (payload) => {
    setAddLoader(true);
    const response = await addItemApi(payload);

    if (response && response.status) {
      dispatch(setSectionHighlighter({}));
      message.success(response?.data?.response);
      setTimeout(() => {
        window.location.href = `${ReservationDetailRoute}?id=${reservationId}`;
      }, 500);
    } else {
      message.error(response?.data?.response?.data?.response || SOMETHING_WENT_WRONG);
    }
    setAddLoader(false);
  };

  const handleSelect = (val, record) => {
    if (selectedTravelers.length) {
      if (page === "inventory") {
        if (record.isOnHold) {
          setIsModalOpen({ status: true, data: record });
        } else {
          itemSelector(record);
        }
      } else {
        setUuid(record.uuid);
        setSearchParams(setURL(record.uuid));
      }
    } else {
      message.error("Please Select Atleast 1 Traveler");
    }
  };

  return (
    <>
      <Table
        columns={column}
        // rowSelection={{}}
        expandable={{
          expandedRowRender: (record) => (
            <p
              style={{
                margin: 0,
              }}
            >
              {record.description}
            </p>
          ),
        }}
        loading={loading || addLoader}
        expandIcon={({ expanded, onExpand, record }) => (
          <>
            <Button type="primary" onClick={(e) => handleSelect(e, record)}>
              Select
            </Button>
            {page === "inventory" ? (
              <span
                ref={modalRef}
                onClick={() => {
                  itemSelector(isModalOpen?.data);
                }}
              ></span>
            ) : null}
          </>
        )}
        // defaultExpandAllRows
        dataSource={eventData}
        pagination={false}
      />
    </>
  );
}

export default Reservationprimary_table;
