import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  message,
} from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  AccessibilityHelp,
  Alignment,
  AutoImage,
  Autosave,
  Bold,
  Base64UploadAdapter,
  CloudServices,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  ImageBlock,
  ImageInline,
  ImageInsert,
  ImageInsertViaUrl,
  ImageResize,
  ImageToolbar,
  ImageUpload,
  ImageStyle,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  LinkImage,
  ListProperties,
  Paragraph,
  SelectAll,
  SimpleUploadAdapter,
  SourceEditing,
  Style,
  Table,
  TableCaption,
  TableCellProperties,
  TableColumnResize,
  TableProperties,
  TableToolbar,
  Underline,
  Undo,
} from "ckeditor5";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createOrUpdateCategoryApi,
  getAccomodationTypesByEventIdApi,
  getFlightsListApi,
  getSingleCategoryApi,
} from "../../../../../apis/eventsApi";
import { getTaxonomyList } from "../../../../../others/commonApiCalls/commonApiCalls";
import { AccommodationOrCategoryCreated } from "../../../../../redux/features/eventFeatures/eventsSlice";
import { SOMETHING_WENT_WRONG } from "../../../../../utils/constants";
import { decodeContent } from "../../../../../utils/contentParser";

const EventAddCategory = ({ addonId, eventId, eventData }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const showDrawer = (val) => {
    setOpen(true);
  };

  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          showDrawer("Add");
        }}
      >
        Category
      </div>
      {open && (
        <AddCategoryDrawer
          loading={loading}
          setLoading={setLoading}
          open={open}
          setOpen={setOpen}
          eventId={eventId}
          addonId={addonId}
          eventData={eventData}
        />
      )}
    </>
  );
};

export default EventAddCategory;
export const checkAddonIsAirType = (addonType) => {
  return addonType &&
    ["air", "pre_extension_air", "post_extension_air"].includes(addonType)
    ? true
    : false;
};

export const checkAddonIsDiningType = (addonType) => {
  return addonType == "dining"
};

export const AddCategoryDrawer = ({
  open,
  categoryId,
  eventId,
  addonId,
  setOpen,
  eventData,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [isCategoryLoading, setIsCategoryLoading] = useState(false);
  const [accomodationTypes, setAccomodationTypes] = useState([]);
  const [subAccomodationTypes, setSubAccomodationTypes] = useState([]);
  const [accomodationLoading, setAccommodationLoading] = useState(false);
  const [minRewardStatus, setMinRewardStatus] = useState([]);
  const [rewardStatusLoading, setRewardStatusLoading] = useState(false);
  const [editorInitData, setEditorInitData] = useState("");
  const [editor, setEditor] = useState(null);
  const editorRef = useRef(null);
  const [editorInstance, setEditorInstance] = useState(null);
  const [flightsList, setFlightsList] = useState([]);
  const [loadingFlight, setLoadingFlight] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [diningTimeAssigned, setDiningTimeAssigned] = useState([]);
  const [loadingDiningTimeAssigned, setLoadingDiningTimeAssigned] =   useState(false);

  useEffect(() => {
    if (open) {
      getAccomodationTypesByEventId();
      getTaxonomyList(
        "min_reward_status",
        setMinRewardStatus,
        setRewardStatusLoading
      );
      if (checkAddonIsAirType(eventData?.field_add_ons_type?.key)) {
        getFlightsList(addonId);
      }
      if(checkAddonIsDiningType(eventData?.field_add_ons_type?.key)){
        getTaxonomyList(
          `dining_time_assigned?filter[field_dining_time_assigned_event.id]=${eventData?.field_event.id}`,
          setDiningTimeAssigned,
          setLoadingDiningTimeAssigned
        );
      }
    }
  }, [open]);

  useEffect(() => {
    if (categoryId && open && editorInstance) {
      getCategoryDetail();
      setIsEdit(true);
    }
  }, [categoryId, open, editorInstance]);

  const onBeforeLoad = (e) => {
    setEditor(e.editor);
  };
  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };
  const getFlightsList = async (addonUuid) => {
    setLoadingFlight(true);
    const res = await getFlightsListApi(addonUuid);
    if (res.status) {
      setFlightsList(res?.data?.data);
      setLoadingFlight(false);
    } else {
      setFlightsList([]);
      setLoadingFlight(false);
      message.error(SOMETHING_WENT_WRONG);
    }
  };

  const editorConfig = {
    allowedContent: true,
    extraAllowedContent: true,
    toolbar: {
      items: [
        "undo",
        "redo",
        "|",
        "sourceEditing",
        "findAndReplace",
        "selectAll",
        "|",
        "heading",
        "style",
        "|",
        "fontSize",
        "fontFamily",
        "fontColor",
        "fontBackgroundColor",
        "|",
        "bold",
        "italic",
        "underline",
        "|",
        "link",
        "insertImage",
        "insertTable",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
        "indent",
        "outdent",
        "|",
        "accessibilityHelp",
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      AutoImage,
      Autosave,
      Bold,
      Base64UploadAdapter,
      CloudServices,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      ImageBlock,
      ImageInline,
      ImageInsert,
      ImageInsertViaUrl,
      ImageResize,
      ImageStyle,
      ImageToolbar,
      ImageUpload,
      Indent,
      IndentBlock,
      Italic,
      Link,
      LinkImage,
      List,
      ListProperties,
      Paragraph,
      SelectAll,
      SourceEditing,
      Style,
      Table,
      TableCaption,
      TableCellProperties,
      TableColumnResize,
      TableProperties,
      TableToolbar,
      Underline,
      Undo,
    ],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "|",
        "imageStyle:inline",
        "imageStyle:wrapText",
        "imageStyle:breakText",
        "|",
        "resizeImage",
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    list: {
      properties: {
        styles: true,
        startIndex: true,
        reversed: true,
      },
    },
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
        {
          name: "Code (dark)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-dark"],
        },
        {
          name: "Code (bright)",
          element: "pre",
          classes: ["fancy-code", "fancy-code-bright"],
        },
      ],
    },
    table: {
      contentToolbar: [
        "tableColumn",
        "tableRow",
        "mergeTableCells",
        "tableProperties",
        "tableCellProperties",
      ],
    },
  };
  const getAccomodationTypesByEventId = async () => {
    const SUB_GROUP_VALUES = [0, 1];
    try {
      setAccommodationLoading(true);
      const [accomodation, subAccomodation] = await Promise.all(
        SUB_GROUP_VALUES.map((subGroup) =>
          getAccomodationTypesByEventIdApi(addonId || eventId, subGroup)
        )
      );
      setAccomodationTypes(accomodation?.data || []);
      setSubAccomodationTypes(subAccomodation?.data || []);
    } catch (error) {
      message.error(SOMETHING_WENT_WRONG);
    }
    setAccommodationLoading(false);
  };

  const getCategoryDetail = async () => {
    setIsCategoryLoading(true);
    const response = await getSingleCategoryApi(categoryId);
    if (response?.status) {
      const categoryDetail = response?.data?.data || {};
      if (editorRef.current && categoryDetail?.field_highlights?.value) {
        editorRef.current.editor.setData(
          categoryDetail?.field_highlights?.value
        );
      }
      form.setFieldsValue({
        field_category_code: categoryDetail?.field_category_code || "",
        name: categoryDetail?.name || "",
        field_flight: categoryDetail?.field_flight?.id || "",
        field_dining_time_assigned: categoryDetail?.field_dinning_assigned?.id || "",
        field_accommodation_type:
          categoryDetail?.field_accommodation_type?.id || null,
        field_accommodation_type_sub_group:
          categoryDetail?.field_accommodation_type_sub_group?.id || null,
        field_description: categoryDetail?.field_description || "",
        field_display_order: categoryDetail?.field_display_order ?? "",
        field_retail_price: categoryDetail?.field_retail_price ?? "",
        field_staff_price: categoryDetail?.field_staff_price ?? "",
        field_net_rate: categoryDetail?.field_net_rate || "",
        field_min_reward_status:
          categoryDetail?.field_min_reward_status?.id || null,
        field_max_reward_status:
          categoryDetail?.field_max_reward_status?.id || null,
        field_agent_display: categoryDetail?.field_agent_display || "",
        field_web_enabled: categoryDetail?.field_web_enabled || "",
      });
    } else {
      message.error(SOMETHING_WENT_WRONG);
    }
    setIsCategoryLoading(false);
  };

  const onFinishHandler = (values) => {
    const {
      field_agent_display,
      field_category_code,
      name,
      field_description,
      field_display_order,
      field_retail_price,
      field_staff_price,
      field_net_rate,
      field_category_pricing,
      field_web_enabled,
      field_flight,
      field_dining_time_assigned,
    } = values;
    const field_highlights = editorRef.current?.editor.getData();
    const payload = {
      data: {
        type: "category--category",
        attributes: {
          status: 1,
          name: name,
          field_category_code: field_category_code,
          field_description: field_description,
          field_display_order: field_display_order,
          field_retail_price: field_retail_price
            ? Number(field_retail_price)
            : 0,
          field_staff_price: field_staff_price ? Number(field_staff_price) : 0,
          field_net_rate: field_net_rate,
          field_agent_display: Boolean(field_agent_display),
          field_web_enabled: Boolean(field_web_enabled),
          field_category_pricing: field_category_pricing,
          field_highlights: field_highlights,
        },
        relationships: {
          field_accommodation_type: {
            data: values?.field_accommodation_type && {
              type: "accommodation_type--accommodation_type",
              id: values?.field_accommodation_type,
            },
          },
          field_accommodation_type_sub_group: {
            data: values?.field_accommodation_type_sub_group && {
              type: "accommodation_type--accommodation_type",
              id: values?.field_accommodation_type_sub_group,
            },
          },
          field_min_reward_status: {
            data: values?.field_min_reward_status && {
              type: "taxonomy_term--min_reward_status",
              id: values?.field_min_reward_status,
            },
          },
          field_max_reward_status: {
            data: values?.field_max_reward_status && {
              type: "taxonomy_term--min_reward_status",
              id: values?.field_max_reward_status,
            },
          },
          field_event: {
            data: {
              type: "event--event",
              id: addonId || eventId,
            },
          },
        },
      },
    };
    if (open) {
      if (checkAddonIsAirType(eventData?.field_add_ons_type?.key)) {
        payload.data.relationships.field_flight = {
          data: field_flight
            ? {
                type: "flights--flights",
                id: field_flight || null,
              }
            : null,
        };
      }
      if (checkAddonIsDiningType(eventData?.field_add_ons_type?.key)) {
        payload.data.relationships.field_dinning_assigned = {
          data: field_dining_time_assigned
            ? {
                type: "taxonomy_term--dining_time_assigned",
                id: field_dining_time_assigned || null,
              }
            : null,
        };
      }
      if (categoryId) {
        payload["data"]["id"] = categoryId;
      }
      createOrUpdateCategory(payload);
    }
  };
  const createOrUpdateCategory = async (payload) => {
    setIsCategoryLoading(true);
    const response = await createOrUpdateCategoryApi({
      id: categoryId,
      payload,
    });
    if (response?.status) {
      dispatch(AccommodationOrCategoryCreated());
      onClose();
      message.success(
        !categoryId
          ? "Category added successfully"
          : "Category updated successfully"
      );
    } else {
      message.error(response?.data?.message || SOMETHING_WENT_WRONG);
    }
    setIsCategoryLoading(false);
  };

  return (
    <Drawer
      title={isEdit ? "Edit Category" : "Add Category"}
      maxWidth={425}
      onClose={onClose}
      open={open}
      bodyStyle={{ paddingBottom: "32px" }}
      extra={
        <Space>
          <Button
            onClick={() => form.submit()}
            type="primary"
            loading={isCategoryLoading}
          >
            Save
          </Button>
        </Space>
      }
    >
      <Form layout="vertical" form={form} onFinish={onFinishHandler}>
        <Spin spinning={isCategoryLoading} size="large">
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                label="Category Code"
                name="field_category_code"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
                rules={[
                  {
                    required: true,
                    message: "Category code is required",
                  },
                ]}
              >
                <Input
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Category Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please Enter category name",
                  },
                ]}
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>
            {checkAddonIsAirType(eventData?.field_add_ons_type?.key) && (
              <Col span={24}>
                <Form.Item
                  label="Flight"
                  name="field_flight"
                  style={{
                    fontFamily: "'Poppins', sans-serif",
                    fontWeight: "400",
                    fontSize: "14px",
                    lineHeight: "22px",
                  }}
                >
                  <Select
                    allowClear
                    style={{ fontFamily: "'Poppins', sans-serif" }}
                    loading={loadingFlight}
                    options={
                      flightsList?.length
                        ? flightsList?.map((flight, index) => ({
                            key: flight.flight_uuid,
                            value: flight?.flight_uuid,
                            label: flight.flight_name,
                          }))
                        : []
                    }
                  />
                </Form.Item>
              </Col>
            )}
            {checkAddonIsDiningType(eventData?.field_add_ons_type?.key) && (
              <Col span={24}>
                <Form.Item
                  name="field_dining_time_assigned"
                  label="Dining Time Assigned"
                >
                  <Select
                    allowClear
                    showSearch
                    showArrow
                    filterOption={(input, option) =>
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    }
                    options={diningTimeAssigned.map(
                      (diningTimeAssignedItem) => ({
                        key: diningTimeAssignedItem.drupal_internal__tid,
                        value: diningTimeAssignedItem.id,
                        label: diningTimeAssignedItem.name,
                      })
                    )}
                    loading={loadingDiningTimeAssigned}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={24}>
              <Form.Item
                label="Accommodation Type"
                name="field_accommodation_type"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  allowClear
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  loading={accomodationLoading}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={
                    accomodationTypes?.length
                      ? accomodationTypes?.map((acc, index) => ({
                          key: acc.uuid,
                          value: acc?.uuid,
                          label: decodeContent(acc.name),
                        }))
                      : []
                  }
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Accommodation Type Sub Group"
                name="field_accommodation_type_sub_group"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  allowClear
                  loading={accomodationLoading}
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={
                    subAccomodationTypes?.length
                      ? subAccomodationTypes?.map((acc, index) => ({
                          key: acc.uuid,
                          value: acc?.uuid,
                          label: acc.name,
                        }))
                      : []
                  }
                ></Select>
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Description"
                name="field_description"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <div className="textarea_row">
                <Form.Item
                  label="Highlights"
                  name="field_highlights"
                  className="ckeitor-field-wrapper"
                >
                  {/* <ReactQuill theme="snow" value={value} onChange={setValue} /> */}
                  <CKEditor
                    editor={ClassicEditor}
                    ref={editorRef}
                    config={editorConfig}
                    data={editorInitData}
                    onReady={(editor) => {
                      setEditorInstance(editor);
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setEditorInitData(data);
                      form.setFieldsValue({ field_highlights: data });
                    }}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Display Order"
                name="field_display_order"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "." ||
                      evt.key === "ArrowUp" ||
                      evt.key === "ArrowDown"
                    ) {
                      evt.preventDefault();
                    }
                    evt.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Retail Price"
                name="field_retail_price"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      // evt.key === "." ||
                      evt.key === "ArrowUp" ||
                      evt.key === "ArrowDown" ||
                      (evt.target.value.split(".")?.[0]?.length >= 7 &&
                        evt.key != "Backspace")
                    ) {
                      evt.preventDefault();
                    }
                    evt.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Staff Price"
                name="field_staff_price"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input
                  type="number"
                  min={0}
                  autoComplete="nope"
                  pattern="/^[0-9\b]+$/"
                  onKeyDown={(evt) => {
                    if (
                      evt.key === "e" ||
                      evt.key === "-" ||
                      evt.key === "+" ||
                      evt.key === "ArrowUp" ||
                      evt.key === "ArrowDown" ||
                      (evt.target.value.split(".")?.[0]?.length >= 7 &&
                        evt.key != "Backspace")
                    ) {
                      evt.preventDefault();
                    }
                    evt.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="Net Rate"
                name="field_net_rate"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Input
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Min Reward Status"
                name="field_min_reward_status"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  loading={rewardStatusLoading}
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={
                    minRewardStatus?.length
                      ? minRewardStatus.map((item) => ({
                          key: item?.id,
                          label: item?.name,
                          value: item?.id,
                        }))
                      : []
                  }
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="Max Reward Status"
                name="field_max_reward_status"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Select
                  style={{ fontFamily: "'Poppins', sans-serif" }}
                  loading={rewardStatusLoading}
                  showSearch
                  showArrow
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  options={
                    minRewardStatus?.length
                      ? minRewardStatus.map((item) => ({
                          key: item?.id,
                          label: item?.name,
                          value: item?.id,
                        }))
                      : []
                  }
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col xs={20} md={20} lg={24}>
              <Form.Item
                className="swith_form"
                label="Agent Display"
                name="field_agent_display"
                valuePropName="checked"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Switch />
              </Form.Item>
            </Col>

            <Col xs={20} md={20} lg={24}>
              <Form.Item
                className="swith_form"
                label="Web Enabled"
                name="field_web_enabled"
                valuePropName="checked"
                style={{
                  fontFamily: "'Poppins', sans-serif",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "22px",
                }}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Spin>
      </Form>
    </Drawer>
  );
};
