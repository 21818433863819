import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { Country } from "country-state-city";

const MobileInput = ({ label, extension, phoneName, form, countryChange }) => {
    const [extValue, setExtValue] = useState();
    const [phoneValue, setPhoneValue] = useState();
    const countriesList = Country.getAllCountries();

    useEffect(() => {
        setTimeout(() => {
            const extInputValue = form.getFieldValue(extension)
            const phoneInputValue = form.getFieldValue(phoneName)
            setExtValue(extInputValue)
            setPhoneValue(phoneInputValue)
        }, 100);
    }, [form, countryChange])

    const handleKeyUp = (event) => {
        const key = event.key.toLowerCase();
        const isAlphaCharacter = /^[a-z]$/.test(key) || /^[^\w\s-]+$/.test(key);

        if (isAlphaCharacter) {
            return true;
        }
        return false;
    };

    const onChange = (e, phoneName) => {

        const value = e.target.value;
        if (extValue === "+1" && value.length === 3) {
            let firstPart = value.substring(0, 3);
            let secondPart = value.substring(3);
            let inputVal = `${firstPart}-${secondPart}`;
            form.setFieldValue(phoneName, inputVal);
            setPhoneValue(inputVal);
        } else if (extValue === "+1" && value.length === 7) {
            let firstPart = value.substring(0, 7);
            let secondPart = value.substring(7);
            let inputVal = `${firstPart}-${secondPart}`;
            form.setFieldValue(phoneName, inputVal);
            setPhoneValue(inputVal);
        } else {
            setPhoneValue(value)
        }
    }

    const onExtensionChange = (e) => {
        setExtValue(e)
        if (e === "+1" && phoneValue && phoneValue.length) {
            if (phoneValue.length > 6) {
                const modifiedPhone = phoneValue.slice(0, 3) + '-' + phoneValue.slice(3, 6) + '-' + phoneValue.slice(6);
                setPhoneValue(modifiedPhone);
                form.setFieldValue(phoneName, modifiedPhone);
            }
        }
    };

    const handlePaste = (e) => {
        let pastedData = e.clipboardData.getData("Text").replace(/\D/g, "");
        if (extValue === "+1" && pastedData.length >= 10) {
            const formattedValue = `${pastedData.slice(0, 3)}-${pastedData.slice(3, 6)}-${pastedData.slice(6, 10)}`;
            form.setFieldValue(phoneName, formattedValue);
            setPhoneValue(formattedValue);
            e.preventDefault();
        } else {
            form.setFieldValue(phoneName, pastedData);
            setPhoneValue(pastedData);
            e.preventDefault();
        }
    };

    const countryCodeSelector = (name) => (
        <Form.Item name={name} noStyle initialValue={"+1"}>
            <Select style={{ width: "90px" }} showSearch onChange={onExtensionChange}>
                {countriesList.map((country, index) => (
                    <Select.Option
                        value={`+${country.phonecode.replace("+", "")}`}
                        key={index}
                    >
                        +{country.phonecode.replace("+", "")}
                    </Select.Option>
                ))}
            </Select>
        </Form.Item>
    );

    const isAllowedKeyCombination = (evt) => {
        return (evt.ctrlKey || evt.metaKey) && (
            evt.key === "a" ||  
            evt.key === "c" || 
            evt.key === "x" ||  
            evt.key === "v" ||  
            evt.key === "z" ||  
            evt.key === "y"    
        );
    };

    return (
        <Form.Item
            className="asterick-align number-arrows"
            label={label}
            name={phoneName}
        >
            <Input
                addonBefore={countryCodeSelector(extension)}
                type="text"
                autoComplete="nope"
                onKeyDown={(evt) => {
                    if (isAllowedKeyCombination(evt)) {
                        return;
                    }
                    if (
                        evt.key === "e" ||
                        evt.key === "+" ||
                        evt.key === "." ||
                        evt.key === "ArrowUp" ||
                        evt.key === "ArrowDown" || handleKeyUp(evt)
                    ) {
                        evt.preventDefault();
                    } else {
                        onChange(evt, phoneName);
                    }
                }}
                onPaste={handlePaste}
                onInput={(e) => (e.target.value = e.target?.value?.slice(0, 12))}
            />
        </Form.Item>
    );
};

export default MobileInput;