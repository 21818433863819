import { Table, Switch } from "antd";
import "./Category_information.less";
import { Divider } from "antd";
import React from "react";
import parse from "html-react-parser";
import DataLoader from "../../../../../others/Loaders/DataLoader";
import { addCommasInPricing } from "../../../../../utils/contentParser";
import { checkAddonIsAirType, checkAddonIsDiningType  } from "../CategoriesListing/EventAddCategory";

const columns = [
  {
    title: "Rez #",
    dataIndex: "rez",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
];

function findHighestOccupancy(pricingData) {
  return pricingData.reduce((highest, item) => {
    const occupancy = parseInt(item.occupancy);
    return !isNaN(occupancy) && occupancy > highest ? occupancy : highest;
  }, 0);
}

function findLowestOccupancy(pricingData) {
  return pricingData.reduce((lowest, item) => {
    const occupancy = parseInt(item.occupancy);
    if (isNaN(occupancy)) {
      return lowest; // Skip invalid values
    }
    return occupancy < lowest ? occupancy : lowest;
  }, Infinity); // Initialize with positive infinity to ensure any valid value will be lower
}

const CategoryInformation = ({
  eventId,
  categoryDetails,
  loadingSingleCategory,
}) => {
  const {
    name,
    field_category_code,
    field_description,
    field_highlights,
    field_display_order,
    field_retail_price,
    field_staff_price,
    field_agent_display,
    field_net_rate,
    field_web_enabled,
    field_accommodation_type,
    field_accommodation_type_sub_group,
    field_max_occupancy,
    field_min_occupancy,
    field_category_pricing,
    field_event,
    field_flight,
    field_dinning_assigned,
  } = categoryDetails || {};
  console.log(categoryDetails, "Here is field dinning assigned++++");
  const data = [
    {
      key: "1",
      rez: "Category Code",
      name: <>{field_category_code || ""}</>,
    },
    {
      key: "2",
      rez: "Category Name",
      name: <>{name || ""}</>,
    },
    {
      key: "3",
      rez: "Accommodation Type",
      name: <>{field_accommodation_type?.name || ""}</>,
    },

    {
      key: "3",
      rez: "Accommodation Type Sub Group",
      name: <>{field_accommodation_type_sub_group?.name || ""}</>,
    },

    {
      key: "4",
      rez: "Description",
      name: <>{field_description || ""}</>,
    },
    {
      key: "4",
      rez: "Highlights",
      name: <>{parse(field_highlights?.value || "")}</>,
    },

    {
      key: "5",
      rez: "Display Order",
      name: <>{field_display_order || ""}</>,
    },

    {
      key: "6",
      rez: "Retail Price",
      name: (
        <>
          {field_retail_price
            ? `$${addCommasInPricing(field_retail_price)}`
            : "$0"}
        </>
      ),
    },
    {
      key: "7",
      rez: "Staff Price",
      name: (
        <>
          {field_staff_price
            ? `$${addCommasInPricing(field_staff_price)}`
            : "$0"}
        </>
      ),
    },

    {
      key: "8",
      rez: "Net Rate",
      name: <>{field_net_rate || ""}</>,
    },

    {
      key: "9",
      rez: "Min Occupancy",
      name: (
        <>
          {field_min_occupancy  ?? 1}
        </>
      ),
    },
    {
      key: "10",
      rez: "Max Occupancy",
      name: (
        <>
           {field_max_occupancy  ?? 1}
        </>
      ),
    },

    {
      key: "11",
      rez: "Agent Display",
      name: (
        <>
          <Switch checked={field_agent_display} disabled />
        </>
      ),
    },

    {
      key: "12",
      rez: "Web Enabled",
      name: (
        <>
          <Switch checked={field_web_enabled} disabled />
        </>
      ),
    },
  ];

  if (checkAddonIsAirType(field_event?.field_add_ons_type?.key)) {
    data.splice(2, 0, {
      key: "flight",
      rez: "Flight",
      name: field_flight?.name,
    });
  }

  if (checkAddonIsDiningType(field_event?.field_add_ons_type?.key)) {
    data.splice(2, 0, {
      key: "dining_time_assigned",
      rez: "Dining Time Assigned",
      name: field_dinning_assigned?.name,
    });
  }

  return (
    <div>
      <div
        className="reservarion_heading bg-white"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h4 style={{ fontFamily: "'Poppins'", margin: "0" }}>
          General Information
        </h4>
      </div>

      <Divider className="global_divider mb-0" />

      <Table
        loading={{ spinning: loadingSingleCategory, indicator: <DataLoader /> }}
        className="reservat_table"
        showHeader={false}
        columns={columns}
        dataSource={data}
        pagination={false}
        size="small"
      />
    </div>
  );
};
export default CategoryInformation;
